import "../styles/app.scss"
import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/Layout"
import SEO from "../components/SEO"
import { motion } from "framer-motion"
import { variantsText } from "../utils"

const Index = ({ data: { prismicHomepage } }) => {
  const { image, title, subtitle } = prismicHomepage.data
  return (
    <Layout>
      <SEO />
      <div className="content">
        <motion.div
          className="content__title"
          initial="hidden"
          animate="visible"
          variants={variantsText}
        >
          <h1> {title[0].text} </h1>
        </motion.div>
        <motion.div
          className="content__subtitle"
          initial="hidden"
          animate="visible"
          variants={variantsText}
        >
          <h2 dangerouslySetInnerHTML={{ __html: subtitle[0].text }}/>
        </motion.div>
        <div className="content__social-links">
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.linkedin.com/in/johnojetunde/"
          >
            LinkedIn
          </a>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://github.com/johnojetunde"
          >
            Github
          </a>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://gitlab.com/johnojetunde"
          >
            Gitlab
          </a>
          <a
            target="_blank"
            rel="noreferrer"
            href="mailto:johnojetunde63@gmail.com"
          >
            Email
          </a>
        </div>
        <motion.div
          className="content__image"
          style={{
            backgroundImage: `url(${image.url})`,
          }}
          initial={{ opacity: 0 }}
          exit={{ opacity: 0 }}
          animate={{ opacity: 1, rotate: [-2, 0, 2] }}
        />
      </div>
    </Layout>
  )
}

export default Index

export const pageQuery = graphql`
  query indexQuery {
    prismicHomepage {
      data {
        image {
          url
        }
        subtitle {
          text
        }
        title {
          text
        }
      }
    }
  }
`
